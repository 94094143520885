header {
  .Head {
    width: 100%;
    height: 60px;
    background-color: #232732;
    color: #eeeeee;
    font-size: 20px;
    display: flex;
    font-family: Roboto;
    min-width: 1200px;

    .dropdownButton {
      position: relative;
      cursor: pointer;
      width: fit-content;
      margin: auto;
      display: flex;

      .mainText {
        display: flex;
        > .typeName {
          margin-left: 10px;
          font-size: 18px;
          font-family: "Noto Sans KR";
        }

        > .name {
          font-size: 20px;
          line-height: 28px;
        }

        > .arrowDown {
          width: 18px;
          height: 18px;
          margin: auto;
          margin-left: 10px;
        }

        > .rotate {
          transform: rotate(-180deg);
        }
      }

      .dropbox {
        position: absolute;
        display: none;
        width: 240px;
        height: 40px;
        background: #000000;
        top: 50px;
        left: 50%;
        transform: translate(-50%, 0%);
        padding: 10px 0 10px 0;
        z-index: 2;
        > .cell {
          height: 40px;
          display: flex;

          > .checkIcon {
            width: 20px;
            height: 20px;
            margin: 10px;
            color: #95e4b3;
          }

          > .hidden {
            visibility: hidden;
          }

          > .name {
            margin: auto 0px auto 0px;
            font-size: 16px;
            line-height: 19px;
          }
          > .typeName {
            font-size: 14px;
            margin: auto 0px auto 5px;
          }
        }
        > .cell:hover {
          background: #555;
        }
      }

      > .show {
        display: block;
      }

      > .dropbox::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #000000 transparent;
      }
    }

    .logoDiv {
      position: absolute;
      height: 60px;
      display: flex;
      cursor: pointer;
      > .logoImg {
        height: 25px;
        margin: auto;
        margin-left: 30px;
      }
    }

    > .Tools {
      position: absolute;
      right: 0;
      top: 5px;
      display: flex;
      flex-direction: row;
      margin: auto;
      margin-right: 20px;

      > .imageBox {
        position: relative;
        cursor: pointer;
        width: 40px;
        height: 40px;
        margin: 5px;
        background-color: #383b45;
        border-radius: 6px;
        display: flex;
        color: #ccc;

        > .imageButton {
          margin: auto;
          // color: #eee;
          width: 20px;
          height: 20px;
        }
        &.hidden {
          display: none;
        }
      }

      .imageBox:hover {
        .imageButton {
          color: #95e4b3;
        }
      }

      > .account {
        width: 40px;
        height: 40px;
        display: flex;
        position: relative;
        margin: auto;
        cursor: pointer;
        color: #48ddf2;
        > .icon {
          width: 20px;
          height: 20px;
          margin: auto;
        }
      }

      > .errorMessage {
        height: 40px;
        display: flex;
        margin: auto;
        margin-right: 5px;
        cursor: pointer;

        .errorInfo {
          display: flex;
          .errorIcon {
            color: #e81c1c;
          }

          .warningIcon {
            color: #ffb068;
          }

          .icon {
            width: 16px;
            height: 16px;
            margin: auto 0px auto 5px;
          }

          .value {
            font-size: 12px;
            margin: auto 2px auto 2px;
          }
        }

        .hidden {
          display: none;
        }
      }

      .tooltip {
        > .tooltiptext {
          position: absolute;
          width: max-content;
          visibility: hidden;
          opacity: 0;
          cursor: default;
          background-color: #000000;
          color: #eeeeee;
          text-align: center;
          padding: 5px 10px;
          font-size: 12px;
          z-index: 2;
          bottom: -100%;
          left: 50%;
          transition: opacity 0.3s;
          transform: translate(-50%, 0%);
        }

        > .tooltiptext::after {
          content: "";
          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent #000000 transparent;
        }
      }
      .tooltip:hover {
        > .tooltiptext {
          visibility: visible;
          opacity: 1;
        }
      }

      .saveButton {
        width: 185px;
        height: 40px;
        color: #232732;
        border-radius: 6px;
        margin: auto;
        margin-left: 15px;
        font-family: "Noto Sans KR";
        font-size: 14px;
        font-weight: bold;
        background-image: linear-gradient(to right, #66dfd9, #95e4b3);
      }
    }
  }
}
