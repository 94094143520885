$breakpoint-mobile: 300px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1200px;

@mixin mobile {
  @media (min-width: #{$breakpoint-mobile}) and (max-width: #{$breakpoint-tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}


.Landing {
  height: 100%;
  overflow: auto;
  background-color: #fff;
  font-family: "Noto Sans KR";
  letter-spacing: -0.08em;
  -webkit-letter-spacing: -0.08em;
  position: relative;

  .scroll-top-btn {
    width: 45px;
    height: 45px;
    position: fixed;
    right: 30px;
    bottom: 50px;
    z-index: inherit;
  }

  .dim {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 1;
    top: 0px;
    overflow: hidden;
  }

  .mobile-appbar {
    display: none;
    position: fixed;
    z-index: 3;
    width: 100%;
    height: 60px;
    margin-bottom: 0px;
    box-shadow: unset;
    .exp-root {
      margin: 0px;
    }
    .expanded {
      color: #999;
    }
    .summary-root {
      min-height: 60px;
      .expanded {
        margin: 0px;
      }
      .content {
        margin: 0px;
      }
    }

    .detail {
      padding: 0px;
      display: flex;
      flex-flow: column;
      .menu {
        cursor: pointer;
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #333;
        font-family: "Noto Sans KR";
        background-color: white;
        padding-left: 30px;
      }
      .info {
        display: flex;
        flex-flow: column;
        justify-content: center;
        background-color: white;
        color: #999;
        font-size: 14px;
        padding-left: 30px;
        height: 60px;
        padding-bottom: 10px;
        letter-spacing: normal;
        -webkit-letter-spacing: normal;
      }
    }
  }


  .app-bar {
    position: fixed;
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: start;
    flex-flow: row;
    padding-left: 30px;
    padding-right: 30px;
    .logo {
      margin-top: 0;
      padding-left: 0px;
    }
    .toolbar {
      display: flex;
      flex-flow: row;
      align-items: center;
      margin-left: auto;
      .menus {
        display: flex;
        flex-flow: row;
        align-items: center;
        .btn {
          padding: 0px 10px;
          .icon {
            color: #333;
          }
        }
        .items {
          margin-right: 30px;
          color: #666;
          cursor: pointer;
          &.selected, &:hover  {
            color: black;
            font-weight: bold;
          }
        }
      }
    }
  }

  .landing-wrapper {
    .buildit-start-btn {
      padding: 0px 10px;
      .icon {
        color: #333;
      }
    }
    .btn {
      font-size: 14px;
    }

    >.l-container {
      width: 100%;
      
      &.main {
        color: black;
        display: block;
        .carousel {
          .control-arrow {
            &:before {
              display: inline-block;
              border-top: 0px;
              border-bottom: 0px;
              margin: 0px;
            }
            &.control-prev {
              &:before {
                content: url("/img/landing-swiper-left.svg");
                border-right: 0px;
              }
              margin-left: 30px;
            }
            &.control-next {
              &:before {
                content: url("/img/landing-swiper-right.svg");
                border-left: 0px;
              }
              margin-right: 30px;
            }
            &:hover {
              background-color: transparent;
            }   
          }
          .control-dots {
            margin-bottom: 90px;
            padding: 0px;
            .dot {
              background-color: #666;
              width: 20px;
              height: 20px;
              border-radius: 6px;
            }
          }
        }
        .slider-wrapper {
          .slider {
            .slide {
              height: 1024px;
              background-color: white;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .content {
            &.main {
              max-width: 1200px;
              width: 100%;
              display: flex;
              align-items: center;
              .title-box {
                text-align: left;
                box-sizing: border-box;
                padding: 16px;

                .main-title-1,
                .main-title-2 {
                  font-size: 56px;
                  line-height: 66px;
                  font-weight: bold;
                  &.blink-off {                    
                    .typed-cursor--blink {
                      display: none;
                    }
                  }
                }
                .sub-title {
                  font-weight: bold;
                  margin-top: 20px;
                  line-height: 42px;
                  font-size: 34px;
                  letter-spacing: -0.08em;
                }
              }
              .launching-img {
                width: 600px;
                margin-left: auto;
              }
            }
            &.config, &.result, &.designer {
              width: 100% !important;
              >.wrapper {
                max-width: 1200px;
                height: 100%;
                display: flex;
                align-items: center;
                //margin-left: calc(10%);
                position: relative;
                font-size: 56px;
                margin: auto;
                >.info {
                  width: 50%;
                  text-align: left;
                  position: relative;
                  >.title {
                    font-size: 56px;
                    color: #333;
                    font-weight: bold;
                    margin-bottom: 15px;
                  }
                  >.AI-title { 
                    position: absolute;
                    top: -50px;             
                    font-size: 26px;
                    color: #666;
                    
                  }
                  >.content {
                    display: block;
                    font-size: 32px;
                    color: #333;
                    height: 200px;
                  }
                }
                >.display {
                  width: 50%;
                  margin: auto;
                  >.tablet-wrapper {
                    width: 100%;
                    margin-left: auto;
                    border-radius: 10px;
                    border: 3px solid #aaa;
                    background-color: #313131;
                    box-sizing: border-box;
                    >.tablet-body {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      padding: 10px;
                      width: fit-content;
                      video {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .main-title {
          font-size: 72px;
          font-weight: bold;
          height: 100px;
        }
        .sub-title {
          font-size: 48px;
        }
        .btn-groups {
          display: flex;
          align-items: center;
          .btn {
            width: 210px;
            margin-right: 5px;
            margin-left: 5px;
            font-size: 14px;
            letter-spacing: -0.08em;
            -webkit-letter-spacing: -0.08em;
          }
        }
      }
      &.short-introduction {
        height: 524px;
        background: url("/img/landing_img_short.png");
        background-size: cover;
        background-position-y: 100%;
        >.wrapper {
          background-color: rgba(0, 0, 0, 0.6);
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #eee;
          .main-title {
            font-size: 48px;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            line-height: 60px;
            font-weight: bold;
          }
          .sub-title {
            font-size: 32px;
            letter-spacing: -0.08em;
            -webkit-letter-spacing: -0.08em;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            // font-weight: bold;
          }
        }
      }

      &.buildit-system {
        background-color: #f5f5f5;
        >.wrapper {
          max-width: 1200px;
          margin: auto;
          display: flex;
          align-items: row;
          padding: 100px 0px;
          >.title {
            flex: 1;
            padding-top: 300px;
            font-size: 48px;
            color: #333;
            font-weight: bold;
            line-height: 60px;
          }
          >.cards {
            display: flex;
            .card {
              background-color: inherit;
              &.AI {
                margin-right: 120px;
              }
              &.DESIGNER {
                margin-top: 200px;
              }
              img {
                width: 366px;
                height: 484px;
              }
              >.title {
                text-align: center;
                color: #333;
                font-size: 18px;
                font-weight: bold;
                margin: 30px 0px 15px 0px;
                display: flex;
                justify-content: center;
                >.sub-title {
                  color: #666;
                  margin-left: 5px;
                  font-weight: 100;
                  font-size: 18px;
                }
              }
              >.info {
                font-size: 24px;
                letter-spacing: -0.08em;
                -webkit-letter-spacing: -0.08em;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                margin-bottom: 20px;
              }
              .btn {
                width: 210px;
                margin: auto;
                font-size: 14px;
              }
            }
          }
        }
      }
      &.buildit-reason1 {
        padding: 100px 0px;
        >.wrapper {
          max-width: 1200px;
          margin: auto;
          display: flex;
          flex-flow: row;
          >.title {
            font-size: 48px;
            color: #333;
            font-weight: bold;
            line-height: 60px;
            min-width: 268px;
            display: flex;
            flex-flow: column;
            >.sub-title {
              color: #666;
              font-size: 24px;
              font-weight: 100;
            }
          }
          >.content {
            max-width: 712px;
            margin-left: auto;
            >.info-box { 
              margin-bottom: 50px;
              &:last-child {
                margin-bottom: 0px;
              }
              >.title {
                font-size: 32px;
                color: #333;
                margin-bottom: 20px;
                font-weight: bold;
                display: flex;
                align-items: center;

                >.sub-title {
                  color: #666;
                  font-size: 24px;
                  font-weight: normal;
                  margin-left: 10px;
                }
              }
              >.info {
                color: #666;
                font-size: 24px;
              }
            }
          }
        }
      }
      &.buildit-reason2 {
        background-color: #f5f5f5;
        padding: 100px 0px;
        >.wrapper {
          max-width: 1200px;
          margin: auto;
          >.title {
            font-size: 48px;
            color: #333;
            margin-bottom: 100px;
            font-weight: bold;
            text-align: center;
            line-height: 60px;
          }
          >.content {
            display: flex;
            flex-flow: row;
            >.img-wrap {
              video {
                width: 750px;
                height: 488px;
                margin-left: -282px;
              }
            }
            >.info-wrap {
              margin-left: auto;
              max-width: 590px;
              margin-top: auto;
              margin-bottom: auto;
              >.info {
                .icon {
                  color: #333;
                  margin-right: 5px;
                  margin-top: 7px;
                }
                font-size: 24px;
                color: #333;
                margin-bottom: 10px;
                display: flex;                
              }
            }
          }
        }
      }
      &.buildit-sample {
        background-color: #f5f5f5;
        padding: 100px 0px;
        >.wrapper {
          max-width: 1200px;
          margin: auto;
          display: flex;
          flex-flow: column;
          >.main-title {
            font-size: 48px;
            color: #333;
            font-weight: bold;
            margin-bottom: 90px;
            line-height: 60px;
          }
          >.sample {
            margin: auto;
            display: flex;
            flex-flow: row;
            >.title {
              width: 468px;
              >.img-category {
                font-size: 24px;
                line-height: 42px;
                color: #008FA4;
                align-items: center;
              }
              >.img-main-title {
                font-size: 32px;
                color: #333;
                font-weight: bold;
                margin-bottom :14px;
                line-height: 42px;
              }
              >.img-sub-title {
                color: #666666;
                font-size: 18px;
                margin-bottom: 24px;
                line-height: 26px;
              }
              >a {
                text-decoration: none;
                height: 30px;
                font-size: 18px;
                color: #666;
                display: flex;
                align-items: center;
                .icon {
                  color: #666;
                  margin-left: 10px;
                }
              }
              .button {
                width: 210px;
                justify-content: space-between;
                padding: 0px 10px 0px 10px;
                .icon {
                  width: 20px;
                  height: 20px;
                  color: #CCCCCC;
                }
              }
            }
            .content {
              margin-left: 20px;
              cursor: pointer;
              >.cover {
                position: absolute;
                top: inherit;
                left: inherit;
                width: 712px;
                height: 403px;
                background-color: rgba($color: #000000, $alpha: 0.0);
                color: rgba($color: #CCCCCC, $alpha: 0.0);
                align-items: center;
                justify-content: center;
                display: flex;
                opacity: 0.0;
                
                &:hover {
                  background-color: rgba($color: #000000, $alpha: 0.5);
                  color: rgba($color: #CCCCCC, $alpha: 1.0);
                  opacity: 1.0;
                }
                >.info {
                  display: flex;
                  flex-flow: row;
                  width: 171px;
                  height: 27px;
                  align-items: center;
                  font-size: 18px;
                  line-height: 26px;
                  .icon {
                    width: 20px;
                    height: 20px;
                    color: #CCCCCC;
                    margin-left: 10px;
                    
                  }
                }
              }
              img {
                width: 712px;
                height: 403px;
                // height: 430px;
              }
            }
          }
          
        }
      }
      &.price {
        padding: 100px 0px;
        >.wrapper {
          max-width: 1200px;
          margin: auto;
          >.title {
            >.main-title {
              font-size: 48px;
              line-height: 60px;
              color: #333;
              font-weight: bold;
              text-align: center;
            }
            >.sub-title {
              font-size: 32px;
              line-height: 60px;
              color: #333;
              text-align: center;
            }
            margin-bottom: 80px;
          }
          >.content {
            >.price-cards {
              display: flex;
              justify-content: center;
              >.mobile {
                display: none;
              }
              >.no-mobile {
                //
              }
              .card {
                margin: 0px 15px;
                background-color: white;
                border-bottom: 2px solid #D2D2D2;
                &.GOLD {
                  border-bottom: 2px solid #F6D38F;
                }
                &.PLATINUM {
                  border-bottom: 2px solid #48DDF2;
                }
                &.BRONZE {
                  border-bottom: 2px solid #CEABA8;
                }

                width: 273px;
                position: relative;
                >.top-line {
                  position: absolute;
                  width: 100%;
                  height: 30px;
                  display: flex;
                  align-items: center;
                  .text {
                    font-weight: bold;
                    font-size: 12px;
                    margin-left: 16px;
                  }
                }
                &:first-child {                  
                  margin-left: 0px;
                }
                &:last-child {
                  margin-right: 0px;
                }
                >.price-title {
                  // border-top: 10px solid #666;
                  font-size: 32px;
                  line-height: 42px;
                  color: #333;
                  font-weight: bold;
                  padding: 0px 16px;
                  padding-top: 60px;
                  margin-bottom: 10px;
                }
                >.price-info {
                  height: 48px;
                  font-size: 18px;
                  color: #333;
                  font-weight: bold;
                  line-height: 24px;
                  padding: 0px 0px 0px 16px;
                }
                >.price {
                  padding: 0px 16px;
                  color: #333;
                  font-size: 18px;
                  line-height: 24px;
                  font-weight: bold;
                  margin-bottom: 30px;
                  display: flex;
                  align-items: center;
                  flex-flow: column;
                  margin-top: 10px;
                  >.origin-price {
                    margin-left: auto;
                    // color: #999;
                    // font-size: 13px;
                    font-size: 18px;
                    color: #333;
                    // text-decoration: line-through;
                    text-decoration-color: #333;
                    //font-weight: 100;
                    font-weight: 700;
                  }
                  >.discount-price {
                    margin-left: auto;
                  }
                }
                >.point-info {
                  display: none;
                }
                >.price-table {
                  border-top: 2px solid #F5F5F5;
                  >.icon-value {
                    display: flex;
                    height: 40px;
                    align-items: center;
                    border-bottom: 1px solid #F5F5F5;
                    &:nth-child(3) {
                      border-bottom: 0px;
                    }
                    &:first-child {
//                      padding-top: 10px;
                    }
                    >.icon-wrap {
                      padding-left: 10px;
                      .icon {
                        color: #666;
                        margin-top: 5px;
                      }
                    }
                    >.value {
                      width: 100%;
                      box-sizing: border-box;
                      padding-left: 10px;
                      color: #666;
                      font-size: 14px;
                      font-weight: 500;
                    }
                  }
                  >.icon-value-table {
                    display: flex;
                    margin-bottom: 5px;
                    &:last-child {
                      margin-bottom: 30px;
                    }
                    >.icon-wrap {
                      padding-left: 10px;
                      >img {
                        margin-top: 11px;
                      }
                      >.info-icon {
                        color: #666;
                        width: 18px;
                        height: 18px;
                        margin-top: 11px;
                      }
                    }
                    >.value {
                      width: 100%;
                      padding-left: 10px;
                      >.key-value {
                        &.all {
                          height: 40px;
                          font-weight: 500;
                        }
                        &.buildit, &.service {
                          height: 24px;
                        }
                        display: flex;
                        align-items: center;
                        width: inherit;
                        >.key {
    
                        }
                        >.value {
                          margin-left: auto;
                          margin-right: 20px
                        }
                      }
                    }
                  }
                }
              }

            }   
            .service-info {
              position: relative;
              max-width: 1200px;
              background-color: white;
              border-bottom: 2px solid #E6E5E5;
              margin: auto;
              box-sizing: border-box;
              margin-top: 50px;
              display: flex;
              flex-flow: column;
              >.service-request {
                display: flex;
                margin-top: 36px;
                align-items: center;
                margin-bottom: 30px;
                >.title {
                  font-size: 28px;
                  font-weight: bold;
                  padding: 0px 16px;
                  display: flex;
                  align-items: center;
                }
                >.contact, >.email {
                  font-size: 18px;
                  color: #666;
                  padding: 0px 16px;
                  font-weight: bold;
                  display: flex;
                  align-items: center;
                  .icon {
                    color: #666;
                  }
                }
              }
              >.info-wrapper {
                display: flex;
                flex-flow: row;
                >.consulting-info {
                  >.title {
                    color: #333;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 15px;
                  }
                  padding: 0px 16px;
                  margin-bottom: 20px;
                  >.content-wrap {
                    margin-top: 30px;
                    margin-bottom: 15px;
                    display: flex;
                    .content {
                      color: #666;
                      font-size: 14px;
                      display: flex;
                      flex-flow: column;
                    }
                  }
                }
  
                >.info-wrap {
                  padding: 0px 16px;
                  margin-bottom: 20px;
                  >.title {
                    color: #333;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 15px;
                  }
                }
              }
              
              .table {
                display: flex;
                flex-flow: column;
                min-width: 556px;
                >.tr {
                  display: flex;
                  align-items: center;
                  border-top: 2px solid #ccc;
                  color: #666;
                  &:last-child {
                    border-bottom: 2px solid #ccc;
                  }
                  >.th {
                    display: flex;
                    align-items: center;
                    height: 35px;
                    min-width: 158px;
                    background-color: #E2F2F4;
                    padding-left: 5px;
                  }
                  >.td {
                    display: flex;
                    align-items: center;
                    height: 35px;
                    padding-left: 5px;
                  }
                }
              }

              >.top-line {
                position: absolute;
                height: 10px;
              }
              // >.info-wrap {
              //   border-top: 2px solid #f5f5f5;
              //   padding-top: 20px;
              //   margin-top: 52px;
              //   &:last-child {
              //     margin-bottom: 20px;
              //   }
              //   >.info-content {
              //     padding-left: 16px;
              //     padding-right: 16px;
              //     margin-bottom: 14px;
              //     font-weight: 500;
              //     >.title {
              //       color: #666;
              //       font-size: 14px;
              //       margin-bottom: 5px;
              //       line-height: 148%;
              //     }
              //     >.content {
              //       color: #333;
              //       font-size: 14px;
              //       line-height: 22px;
              //       display: flex;
              //       flex-flow: column;
              //     }
              //   }
              // }
            }
          }
          >.sub-content {
            margin-top: 50px;
            display: flex;
            >.contact {
              width: calc(50% - 10px);
              >.contact-box {
                &:last-child {
                  margin-bottom: 0px;
                }
                margin-bottom: 20px;
                >.title {
                  font-size: 14px;
                  color: #666;
                }
                >.value {
                  font-size: 18px;
                  font-weight: bold;
                  color: #333;
                }
              }
            }
            >.detail-infomation {
              width: calc(50% - 10px);
              margin-left: auto;
              padding-left: 20px;
              box-sizing: border-box;
              >.info-box {
                &:last-child {
                  margin-bottom: 0px;
                }
                margin-bottom: 20px;
                >.title {
                  font-size: 14px;
                  color: #666;
                }
                >.value {
                  font-size: 18px;
                  color: #333;
                }
              }
            }
          }
        }
      }
      &.finish {
        height: 488px;
        background: url("/img/landing_img_finish.png");
        background-size: cover;
        background-position-y: 100%;
        >.wrapper {
          background-color: rgba(0, 0, 0, 0.6);
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #eee;
          .main-title {
            font-size: 48px;
            line-height: 60px;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            margin-bottom: 50px;
          }
          .btn-groups {
            display: flex;
            align-items: center;
            justify-content: center;
            .btn {
              width: 210px;
              margin-right: 5px;
              margin-left: 5px;
              font-size: 14px;
              letter-spacing: -0.08em;
              -webkit-letter-spacing: -0.08em;
            }
          }
        }
      }
      &.footer {
        padding: 30px 0px;
        >.wrapper {
          max-width: 1200px;
          margin: auto;
          >.content {
            display: flex;
            >.links {
              >.link-divider {
                height: 30%;
                margin: 0px 10px;
                border: 1px solid #333;
              }
              >.link {
                color: #333;
                font-weight: bold;
                font-size: 18px;
                text-decoration: none;
              }
              display: flex;
              align-items: center;
            }
            .btn {
              width: 210px;
              margin-left: auto;
              align-items: center;
            }
          }
          >.footer-foot {
            margin-top: 10px;
            display: flex;
            align-items: center;
            >.copyright {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}

@include tablet {
  .Landing {
    height: 100%;
    overflow: auto;
    background-color: #fff;
    font-family: "Noto Sans KR";
    letter-spacing: -0.08em;
    -webkit-letter-spacing: -0.08em;
    .landing-wrapper {
      >.l-container {
        width: 100%;
  
        &.main {
          color: black;
          display: block;
          .carousel {
            .control-arrow {
              top: 860px;

              &:before {
                display: inline-block;
                border-top: 0px;
                border-bottom: 0px;
                margin: 0px;
              }
              &.control-prev {
                &:before {
                  content: url("/img/landing-swiper-left.svg");
                  border-right: 0px;
                }
                margin-left: 30px;
              }
              &.control-next {
                &:before {
                  content: url("/img/landing-swiper-right.svg");
                  border-left: 0px;
                }
                margin-right: 30px;
              }
              &:hover {
                background-color: transparent;
              }   
            }
            .control-dots {
              margin-bottom: 70px;
              padding: 0px;
              .dot {
                background-color: #666;
                width: 20px;
                height: 20px;
                border-radius: 6px;
              }
            }
          }
          .slider-wrapper {
            .slider {
              .slide {
                height: 1024px;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .content {
              &.main {
                width: 662px;
                display: flex;
                align-items: unset;
                justify-content: unset;
                flex-flow: column;
                .title-box {
                  text-align: left;
                  box-sizing: border-box;
                  padding: 0px;
                  padding-bottom: 50px;
  
                  .main-title-1,
                  .main-title-2 {
                    font-weight: bold;
                    &.blink-off {                    
                      .typed-cursor--blink {
                        display: none;
                      }
                    }
                  }
                }
                .launching-img {
                  margin: auto;
                  width: 662px;
                }
              }
              &.config, &.result, &.designer {
                width: 100% !important;
                >.wrapper {
                  height: 100%;
                  display: flex;
                  align-items: center;
                  position: relative;
                  font-size: 56px;
                  width: 662px;
                  margin: auto;
                  flex-flow: column;
                  >.info {
                    width: 100%;
                    text-align: left;
                    >.title {
                      // font-size: 3.8vw;
                      font-size: 56px;
                      color: #333;
                      font-weight: bold;
                    }
                    >.content {
                      display: block;
                      // font-size: 2.4vw;
                      font-size: 32px;
                      color: #333;
                      height: 200px;
                    }
                  }
                  >.display {
                    width: 100%;
                    margin-top: 30px; 
                    >.tablet-wrapper {
                      width: 100%;
                      margin-left: 0px;
                      border-radius: 10px;
                      border: 3px solid #aaa;
                      background-color: #313131;
                      box-sizing: border-box;
                      width: 668px;
                      height: 445.33px;   
                      >.tablet-body {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 10px;
                        width: fit-content;
                        video {
                          width: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .main-title {
            font-size: 72px;
            font-weight: bold;
            height: 100px;
          }
          .sub-title {
            font-size: 48px;
          }
        }
        &.short-introduction {
          height: 524px;
          background: url("/img/landing_img_short.png");
          background-size: cover;
          background-position-y: 100%;
          >.wrapper {
            background-color: rgba(0, 0, 0, 0.6);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #eee;
            .main-title {
              font-size: 48px;
              display: flex;
              flex-flow: column;
              align-items: center;
              justify-content: center;
              line-height: 60px;
            }
            .sub-title {
              font-size: 32px;
              letter-spacing: -0.08em;
              -webkit-letter-spacing: -0.08em;
              display: flex;
              flex-flow: column;
              align-items: center;
              justify-content: center;
            }
          }
        }
  
        &.buildit-system {
          background-color: #f5f5f5;
          >.wrapper {
            max-width: 1200px;
            margin: auto;
            display: flex;
            flex-flow: column;
            padding: 100px 0px;
            margin: 0px 50px;
            >.title {
              padding-top: 0px;
              font-size: 48px;
              color: #333;
              font-weight: bold;
              line-height: 60px;
              text-align: center;
            }
            >.cards {
              flex: 1;
              display: flex;
              padding-top: 100px;
              justify-content: center;
              margin-left: -16px;
              margin-right: -16px;
              .card {
                background-color: inherit;
                &.AI {
                  margin-right: 60px;
                }
                &.DESIGNER {
                  margin-top: 200px;
                }
                img {
                  width: 304px;
                  height: 402px;
                }
                >.title {
                  text-align: center;
                  color: #333;
                  font-size: 18px;
                  font-weight: bold;
                  margin: 20px 0px;
                }
                >.info {
                  font-size: 20px;
                  letter-spacing: -0.08em;
                  -webkit-letter-spacing: -0.08em;         
                  display: flex;
                  flex-flow: column;
                  align-items: center;
                  justify-content: center;
                  font-weight: bold;
                  margin-bottom: 20px;
                }
                .btn {
                  width: 210px;
                  margin: auto;
                  font-size: 14px;
                }
              }
              
            }
          }
        }
        &.buildit-reason1 {
          padding: 100px 0px;
          >.wrapper {
            max-width: 1200px;
            margin: auto;
            display: flex;
            flex-flow: column;
            margin-left: 50px;
            >.title {
              font-size: 48px;
              color: #333;
              font-weight: bold;
              line-height: 60px;
            }
            >.content {
              max-width: 712px;
              margin-left: 0px;
              margin-top: 100px;
              >.info-box { 
                margin-bottom: 50px;
                >.title {
                  font-size: 32px;
                  color: #333;
                  margin-bottom: 20px;
                  font-weight: bold;
                }
                >.info {
                  color: #666;
                  font-size: 24px;
                }
              }
            }
          }
        }
        &.buildit-reason2 {
          background-color: #f5f5f5;
          padding: 100px 0px;
          >.wrapper {
            max-width: 1200px;
            margin: auto;
            display: flex;
            flex-flow: column;
            >.title {
              font-size: 48px;
              color: #333;
              margin-bottom: 100px;
              font-weight: bold;
              text-align: center;
            }
            >.content {
              display: flex;
              flex-flow: column;
              >.img-wrap {
                text-align: center;
                video {
                  width: calc(100% - 100px);
                  max-width: calc(100% - 100px);
                  height: unset;
                  margin: auto;
                }
              }
              >.info-wrap {
                margin: auto;
                max-width: unset;
                width: calc(100% - 100px);
                margin-top: 50px;
                margin-bottom: auto;
                >.info {
                  .icon {
                    color: #333;
                    margin-right: 5px;
                    margin-top: 7px;
                  }
                  font-size: 24px;
                  color: #333;
                  margin-bottom: 10px;
                  display: flex;                
                }
              }
            }
          }
        }
        &.buildit-sample {
          padding: 100px 0px;
          >.wrapper {
            max-width: 1200px;
            margin: auto;
            display: flex;
            flex-flow: column;
            margin-left: 50px;
            >.sample {
              flex-flow: column;
              margin: unset;
              .content {
                margin-top: 30px;
                margin-left: 0px;
              }

              // iframe {
              //   width: 642px !important;
              //   height: 362px !important;
              // }

            }
            >.title {
              max-width: 590px;
              >.main-title {
                font-size: 48px;
                color: #333;
                font-weight: bold;
                margin-bottom: 90px;
                line-height: 60px;
              }
              >.img-main-title {
                font-size: 32px;
                color: #333;
                font-weight: bold;
                margin-bottom :10px;
                line-height: 34px;
              }
              >.img-sub-title {
                color: #666666;
                font-size: 24px;
                margin-bottom: 15px;
              }
              >a {
                text-decoration: none;
                height: 30px;
                font-size: 18px;
                color: #666;
  
              }
            }
            >.content {
              margin-left: 0px;
              margin-top: 50px;
              img {
                width: 590px;
                height: 430px;
              }
            }
          }
        }
        &.price {
          padding: 100px 0px;
          >.wrapper {
            max-width: 1200px;
            margin: auto;
            margin: 0px 50px;
            >.title {
              >.main-title {
  
              }
              >.sub-title {
                
              }
            }
            >.content {
              >.price-cards {
                display: flex;
                overflow-x: scroll;
                justify-content: unset;
                padding-bottom: 20px;
                &::-webkit-scrollbar {
                  height: 7px;
                }
                flex-flow: nowrap;

                .card {
                  margin: 0px;
                  flex-grow: 0;
                  flex-shrink: 0;
                  flex-basis: 273px;
                  margin-right: 20px;
                  &:first-child {
                    margin-left: 0px;
                    margin-right: 20px;
                  }
                  &:last-child {
                    margin-left: auto;
                    margin-right: 0px;
                  }
                  >.price-title {
                    // padding-top: 40px;
                    font-size: 32px;
                    line-height: 42px;
                    color: #333;
                    font-weight: bold;
                  }
                  >.price-info {
                    height: 48px;
                    font-size: 16px;
                    color: #333;
                    font-weight: bold;
                    line-height: 24px;
                  }
                  >.price {
                    color: #333;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: bold;
                    text-align: right;
                    margin-bottom: 30px;
                  }
                  >.point-info {
                    display: none;
                  }
                  >.price-table {
                    >.icon-value {
                      display: flex;
                      height: 40px;
                      align-items: center;
                      >.icon-wrap {
                        padding-left: 10px;
                        .icon {
                          color: #666;
                          margin-top: 5px;
                        }
                      }
                      >.value {
                        padding-left: 10px;
                        color: #666;
                        font-size: 14px;
                      }
                    }
                    >.icon-value-table {
                      display: flex;
                      margin-bottom: 10px;
                      >.icon-wrap {
                        padding-left: 10px;
                        >img {
                          margin-top: 11px;
                        }
                      }
                      >.value {
                        width: 100%;
                        padding-left: 10px;
                        >.key-value {
                          &.all {
                            height: 40px;
                          }
                          &.buildit, &.service {
                            height: 24px;
                          }
                          display: flex;
                          align-items: center;
                          width: inherit;
                          >.key {
      
                          }
                          >.value {
                            margin-left: auto;
                            margin-right: 20px
                          }
                        }
                      }
                    }
                  }
                }

              }
              .service-info {
                >.info-wrapper {
                  flex-flow: column;
                  >.consulting-info {
                    >.title {
                      color: #333;
                      font-size: 14px;
                      font-weight: 500;
                      margin-bottom: 15px;
                    }
                    padding: 0px 16px;
                    margin-bottom: 20px;
                    >.content-wrap {
                      margin-bottom: 15px;
                      display: flex;
                      .content {
                        color: #666;
                        font-size: 14px;
                        display: flex;
                        flex-flow: column;
                      }
                    }
                  }
    
                  >.info-wrap {
                    padding: 0px 16px;
                    margin-bottom: 20px;
                    >.title {
                      color: #333;
                      font-size: 14px;
                      font-weight: 500;
                      margin-bottom: 15px;
                    }
                  }
                }
                
                .table {
                  min-width: unset;
                  width: 100%;
                  .tr {
                    min-width: unset;
                    align-items: unset;
                  }
                }
              } 
              
            }
            >.sub-content {
              margin-top: 50px;
              display: flex;
              >.contact {
                width: calc(30% - 10px);
                >.contact-box {
                  &:last-child {
                    margin-bottom: 0px;
                  }
                  margin-bottom: 20px;
                  >.title {
                    font-size: 14px;
                    color: #666;
                  }
                  >.value {
                    font-size: 18px;
                    font-weight: bold;
                    color: #333;
                  }
                }
              }
              >.detail-infomation {
                width: calc(50% - 10px);
                margin-left: 20px;
                padding-left: 20px;
                box-sizing: border-box;
                >.info-box {
                  &:last-child {
                    margin-bottom: 0px;
                  }
                  margin-bottom: 20px;
                  >.title {
                    font-size: 14px;
                    color: #666;
                  }
                  >.value {
                    font-size: 18px;
                    color: #333;
                  }
                }
              }
            }
          }
        }
        &.finish {
          height: 400px;
          background: url("/img/landing_img_finish.png");
          background-size: cover;
          background-position-y: 100%;
          >.wrapper {
            background-color: rgba(0, 0, 0, 0.6);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #eee;
            .main-title {
              font-size: 48px;
              line-height: 60px;
              display: flex;
              flex-flow: column;
              align-items: center;
              justify-content: center;
              font-weight: bold;
              margin-bottom: 30px;
            }
            .sub-title {
              font-size: 24px;
              display: flex;
              flex-flow: column;
              align-items: center;
              justify-content: center;
              font-weight: bold;
              margin-bottom: 20px;
            }
            .btn-groups {
              display: flex;
              align-items: center;
              justify-content: center;
              .btn {
                width: 210px;
                margin-right: 5px;
                margin-left: 5px;
              }
            }
          }
        }
        &.footer {
          padding: 30px 0px;
          >.wrapper {
            max-width: 1200px;
            margin: auto;
            margin: 0px 50px;
            >.content {
              display: flex;
              >.links {
                >.link-divider {
                  height: 30%;
                  margin: 0px 10px;
                  border: 1px solid #333;
                }
                >.link {
                  color: #333;
                  font-weight: bold;
                  font-size: 18px;
                  text-decoration: none;
                }
                display: flex;
                align-items: center;
              }
              .btn {
                width: 210px;
                margin-left: auto;
                align-items: center;
              }
            }
            >.footer-foot {
              margin-top: 10px;
              margin-bottom: 20px;
              display: flex;
              align-items: flex-start;
              flex-flow: column;
              >.copyright {
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }
}
@include mobile {
  .Landing {
    height: 100%;
    overflow: auto;
    background-color: #fff;
    font-family: "Noto Sans KR";
    letter-spacing: -0.08em;
    -webkit-letter-spacing: -0.08em;

    .btn {
      font-weight: bold !important;
    }

    .app-bar {
      display: none !important;
    }
    .mobile-appbar {
      display: block;
      margin-top: 0px;
    }

    .landing-wrapper {
      >.l-container {
        width: 100%;
        // margin: 0px 16px;
        &.main {
          color: black;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .carousel-root {
            width: 100%;
          }
          .carousel {
            .control-arrow {
              top: 525px;

              &:before {
                display: inline-block;
                border-top: 0px;
                border-bottom: 0px;
                margin: 0px;
              }
              &.control-prev {
                &:before {
                  content: url("/img/landing-swiper-left.svg");
                  border-right: 0px;
                }
                margin-left: 0px;
              }
              &.control-next {
                &:before {
                  content: url("/img/landing-swiper-right.svg");
                  border-left: 0px;
                }
                margin-right: 0px;
              }
              &:hover {
                background-color: transparent;
              }   
            }
            .control-dots {
              margin-bottom: 50px;
              padding: 0px;
              .dot {
                background-color: #666;
                width: 10px;
                height: 10px;
                border-radius: 6px;
              }
            }
          }
          .slider-wrapper {
            .slider {
              .slide {
                height: 640px;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .content {
              &.main {
                width: 100% !important;
                display: flex;
                align-items: unset;
                justify-content: unset;
                flex-flow: column;
                .title-box {
                  width: 328px;
                  text-align: left;
                  box-sizing: border-box;
                  margin: auto;
                  padding: 0px;
                  padding-bottom: 20px;
  
                  .main-title-1,
                  .main-title-2 {
                    font-size: 30px;
                    line-height: 36px;
                    font-weight: bold;
                    &.blink-off {                    
                      .typed-cursor--blink {
                        display: none;
                      }
                    }
                  }
                  .sub-title {
                    font-size: 22px;
                    line-height: 28px;
                  }
                }
                .launching-img {
                  width: 328px;
                  margin: auto;
                }
              }
              &.config, &.result, &.designer {
                width: 100% !important;
                >.wrapper {
                  height: 100%;
                  display: flex;
                  align-items: center;
                  position: relative;
                  font-size: 56px;
                  width: 328px;
                  margin: auto;
                  flex-flow: column;
                  >.info {
                    width: 100%;
                    text-align: left;
                    >.title {
                      // font-size: 3.8vw;
                      font-size: 24px;
                      color: #333;
                      font-weight: bold;
                      margin-bottom: 10px;
                    }
                    >.AI-title { 
                      position: absolute;
                      top: -50px;
                      margin-top: 30px;
                      font-size: 14px;
                      color: #666;
                      
                    }
                    >.content {
                      display: block;
                      // font-size: 2.4vw;
                      font-size: 18px;
                      color: #333;
                      height: 100px;
                    }
                  }
                  >.display {
                    width: 100%;
                    margin-top: 30px; 
                    >.tablet-wrapper {
                      width: 100%;
                      margin-left: 0px;
                      border-radius: 10px;
                      border: 3px solid #aaa;
                      background-color: #313131;
                      box-sizing: border-box;
                      width: 328px;
                      height: 218px;   
                      >.tablet-body {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 10px;
                        width: fit-content;
                        video {
                          width: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .main-title {
            font-size: 32px;
            font-weight: bold;
            height: 100px;
          }
          .sub-title {
            font-size: 20px;
            line-height: 28px !important;
            margin-top: 10px !important;
          }
          .btn-groups {
            display: flex;
            align-items: flex-start;
            flex-flow: column;
            .btn {
              margin-bottom: 10px;
            }
          }
        }
        &.short-introduction {
          height: 316px;
          background: url("/img/landing_img_short.png");
          background-size: cover;
          background-position-y: 100%;
          >.wrapper {
            background-color: rgba(0, 0, 0, 0.6);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #eee;
            .main-title {
              font-size: 26px;
              display: flex;
              flex-flow: column;
              align-items: center;
              justify-content: center;
              line-height: 32px;
            }
            .sub-title {
              margin-top: -10px;
              font-size: 16px;
              letter-spacing: -0.08em;
              -webkit-letter-spacing: -0.08em;
              display: flex;
              flex-flow: column;
              align-items: center;
              justify-content: center;
            }
          }
        }
  
        &.buildit-system {
          background-color: #f5f5f5;
          >.wrapper {
            max-width: 1200px;
            margin: auto;
            display: flex;
            flex-flow: column;
            padding: 80px 0px;
            margin: 0px 16px;
            >.title {
              flex: 1;
              padding-top: 0px;
              font-size: 48px;
              color: #333;
              font-weight: bold;
              line-height: 40px;
              text-align: center;
              font-size: 32px;
              margin-bottom: 80px;
            }
            .cards {
              display: flex;
              flex-flow: nowrap;
              overflow-x: scroll;
              justify-content: space-between;
              .card {
                // margin-right: 10px;
                // margin-left: 10px;
                background-color: inherit;
                margin-bottom: 50px;
                flex: 0 0 304px;
                // height: 402px;
                &.AI {
                  padding-top: 0px;
                  margin-right: 10px;
                }
                &.DESIGNER {
                  margin-left: 10px;
                  margin-top: 0px;
                }
                img {
                  width: 100%;
                  height: auto;
                }
                >.title {
                  text-align: center;
                  color: #333;
                  font-size: 18px;
                  font-weight: bold;
                  margin: 16px 0px; 
                }
                >.info {
                  font-size: 18px;
                  letter-spacing: -0.08em;
                  -webkit-letter-spacing: -0.08em;
                  display: flex;
                  flex-flow: column;
                  align-items: center;
                  justify-content: normal;
                  font-weight: bold;
                  margin-bottom: 20px;
                  height: 80px;
                }
                .btn {
                  width: 210px;
                  margin: auto;
                  font-size: 14px;
                }
              }
            }
          }
        }
        &.buildit-reason1 {
          padding: 80px 0px;
          >.wrapper {
            max-width: 1200px;
            margin: auto;
            display: flex;
            flex-flow: column;
            margin: 0px 16px;
            >.title {
              font-size: 32px;
              color: #333;
              font-weight: bold;
              line-height: 40px;
              margin-bottom: 30px;
              margin-left: 0px;
            }
            >.content {
              margin-left: 0px;
              max-width: auto;
              display: flex;
              flex-flow: nowrap;
              overflow-x: scroll;
              &::-webkit-scrollbar {
                height: 7px;
              }
              >.info-box { 
                flex: 0 0 calc(80%);
                margin-right: 20px;
                &:last-child {
                  margin-right: 0px;
                }
                >.title {
                  font-size: 24px;
                  color: #333;
                  margin-bottom: 10px;
                  font-weight: bold;
                }
                >.info {
                  color: #666;
                  font-size: 18px;
                }
              }
            }
          }
        }
        &.buildit-reason2 {
          background-color: #f5f5f5;
          padding: 80px 0px;
          >.wrapper {
            max-width: 1200px;
            margin: auto;
            flex-flow: column;
            >.title {
              font-size: 32px;
              color: #333;
              margin-bottom: 30px;
              font-weight: bold;
              text-align: center;
              line-height: 40px;
            }
            >.content {
              display: flex;
              flex-flow: column;
              >.img-wrap {
                margin: 0px 16px;
                width: calc(100% - 16px);
                height: auto;
                video {
                  width: calc(100% - 16px);
                  margin-left: 0px;
                  margin-bottom: 30px;
                  height: 100%;
                }
              }
              >.info-wrap {
                max-width: 590px;
                margin-top: auto;
                margin-bottom: auto;
                margin: 0px 16px;
                >.info {
                  .icon {
                    color: #333;
                    margin-right: 5px;
                    margin-top: 0px;
                  }
                  font-size: 14px;
                  color: #333;
                  margin-bottom: 10px;
                  display: flex;                
                }
              }
            }
          }
        }
        &.buildit-sample {
          padding: 80px 0px;
          >.wrapper {
            max-width: 1200px;
            margin: auto;
            display: flex;
            flex-flow: column;
            margin: 0px 16px;
            .sample {
              flex-flow: column;
              margin: unset;
              .content {
                margin-top: 30px;
                margin-left: 0px;
              }

              >.title {
                max-width: 590px;
                margin-bottom: 20px;
                >.main-title {
                  font-size: 32px;
                  color: #333;
                  font-weight: bold;
                  margin-bottom: 20px;
                  line-height: 42px;
                }
                >.img-main-title {
                  font-size: 18px;
                  color: #333;
                  font-weight: bold;
                  margin-bottom :10px;
                  line-height: 34px;
                }
                >.img-sub-title {
                  color: #666666;
                  font-size: 14px;
                  margin-bottom: 10px;
                }
                >a {
                  text-decoration: none;
                  height: 30px;
                  font-size: 14px;
                  color: #666;
                }
              }

              iframe {
                width: 375px !important;
                height: 210px !important;
              }

            }
            .main-title {
              font-size: 32px;
              line-height: 38px;
              margin-bottom: 20px;
            }
            .content {
              margin-left: 0px;
              .cover {
                width: 375px !important;
                height: 210px !important;
              }
              img {
                width: 375px !important;
                height: 210px !important;
              }
            }
          }
        }
        &.price {
          background: #FFFFFF;
          padding: 80px 0px;
          >.wrapper {
            max-width: 1200px;
            margin: auto;
            margin: 0px 16px;
            >.title {
              >.main-title {
                font-size: 32px;
                line-height: 60px;
                color: #333;
                font-weight: bold;
                text-align: center;
              }
              >.sub-title {
                font-size: 24px;
                line-height: 30px;
                color: #333;
                text-align: center;
                display: flex;
                flex-flow: column;
              }
              margin-bottom: 50px;
            }
            >.content {
              >.price-cards {
                display: flex;
                overflow-x: scroll;
                padding-bottom: 20px;
                justify-content: unset;
                &::-webkit-scrollbar {
                  height: 7px;
                }
                flex-flow: nowrap;

                .card {
                  margin: 0px;
                  flex-grow: 0;
                  flex-shrink: 0;
                  flex-basis: 273px;
                  margin-right: 20px;
                  &:first-child {
                    margin-left: 0px;
                    margin-right: 20px;
                  }
                  &:last-child {
                    margin-left: auto;
                    margin-right: 0px;
                  }
                  >.price-title {
                    // padding-top: 40px;
                    font-size: 32px;
                    line-height: 42px;
                    color: #333;
                    font-weight: bold;
                  }
                  >.price-info {
                    height: 48px;
                    font-size: 18px;
                    color: #333;
                    font-weight: bold;
                    line-height: 24px;
                  }
                  >.price {
                    color: #333;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: bold;
                    text-align: right;
                    margin-bottom: 30px;
                  }
                  >.point-info {
                    display: none;
                  }
                  >.price-table {
                    >.icon-value {
                      display: flex;
                      height: 40px;
                      align-items: center;
                      >.icon-wrap {
                        padding-left: 10px;
                        .icon {
                          color: #666;
                          margin-top: 5px;
                        }
                      }
                      >.value {
                        padding-left: 10px;
                        color: #666;
                        font-size: 14px;
                      }
                    }
                    >.icon-value-table {
                      display: flex;
                      margin-bottom: 10px;
                      >.icon-wrap {
                        padding-left: 10px;
                        >img {
                          margin-top: 11px;
                        }
                      }
                      >.value {
                        width: 100%;
                        padding-left: 10px;
                        >.key-value {
                          &.all {
                            height: 40px;
                          }
                          &.buildit, &.service {
                            height: 24px;
                          }
                          display: flex;
                          align-items: center;
                          width: inherit;
                          >.key {
      
                          }
                          >.value {
                            margin-left: auto;
                            margin-right: 20px
                          }
                        }
                      }
                    }
                  }
                }
              }  
              .service-info {
                >.service-request {
                  align-items: flex-start;
                  flex-flow: column;
                }
                >.info-wrapper {
                  flex-flow: column;
                  >.consulting-info {
                    >.title {
                      color: #333;
                      font-size: 14px;
                      font-weight: 500;
                      margin-bottom: 15px;
                    }
                    padding: 0px 16px;
                    margin-bottom: 20px;
                    >.content-wrap {
                      margin-bottom: 15px;
                      display: flex;
                      .content {
                        color: #666;
                        font-size: 14px;
                        display: flex;
                        flex-flow: column;
                      }
                    }
                  }
    
                  >.info-wrap {
                    padding: 0px 16px;
                    margin-bottom: 20px;
                    >.title {
                      color: #333;
                      font-size: 14px;
                      font-weight: 500;
                      margin-bottom: 15px;
                    }
                  }
                }
                
                .table {
                  min-width: 120px;
                  .tr {
                    min-width: inherit;
                    align-items: unset;
                    flex-flow: column;
                  }
                }
              } 
            }
            >.sub-content {
              display: flex;
              flex-flow: column;
              margin: 0px;
              margin-top: 50px;
              >.contact {
                width: 100%;
                >.contact-box {
                  &:last-child {
                    margin-bottom: 0px;
                  }
                  margin-bottom: 20px;
                  >.title {
                    font-size: 14px;
                    color: #666;
                  }
                  >.value {
                    font-size: 18px;
                    font-weight: bold;
                    color: #333;
                  }
                }
              }
              >.detail-infomation {
                width: 100%;
                margin-left: 0%;
                padding-left: 0px;
                margin-top: 20px;
                box-sizing: border-box;
                >.info-box {
                  &:last-child {
                    margin-bottom: 0px;
                  }
                  margin-bottom: 20px;
                  >.title {
                    font-size: 14px;
                    color: #666;
                  }
                  >.value {
                    font-size: 18px;
                    color: #333;
                  }
                }
              }
            }
          }
        }
        &.finish {
          height: 350px;
          background: url("/img/landing_img_finish.png");
          background-size: cover;
          background-position-y: 100%;
          >.wrapper {
            background-color: rgba(0, 0, 0, 0.6);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #eee;
            .main-title {
              font-size: 26px;
              line-height: 32px;
              display: flex;
              flex-flow: column;
              align-items: center;
              justify-content: center;
              font-weight: bold;
              margin-bottom: 20px;
            }
            .sub-title {
              font-size: 14px;
              display: flex;
              flex-flow: column;
              align-items: center;
              justify-content: center;
              font-weight: bold;
              margin-bottom: 20px;
            }
            .btn-groups {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-flow: column;
              .btn {
                width: 210px;
                margin-right: 5px;
                margin-left: 5px;
                margin-bottom: 15px;
              }
            }
          }
        }
        &.footer {
          padding: 30px 0px;
          >.wrapper {
            max-width: 1200px;
            margin: auto;
            margin: 0px 16px;
            >.content {
              display: flex;
              >.links {
                >.link-divider {
                  height: 30%;
                  margin: 0px 10px;
                  border: 1px solid #333;
                  display: none;
                }
                >.link {
                  color: #333;
                  font-weight: bold;
                  font-size: 18px;
                  text-decoration: none;
                  margin-bottom: 10px;
                }
                display: flex;
                flex-flow: column;
                align-items: flex-start;
              }
              .btn {
                width: 210px;
                margin-left: auto;
                align-items: center;
                display: none;
              }
            }
            >.footer-foot {
              margin-top: 10px;
              margin-bottom: 20px;
              display: flex;
              flex-flow: column;
              align-items: flex-start;
              color: #666;
              font-weight: bold;
              >.copyright {
                margin-left: 0px;
                margin-top: 5px;
              }
              >.address {
                display: flex;
                flex-flow: column;
              }
            }
          }
        }
      }
    }
  }
}


.block {
  height: 5em;
  line-height: 5em;
  width: 10em;
  background: #464646;
  color: #fdfdfd;
  text-align: center;
  margin: 1em auto;
  text-shadow: 0 0 1px #333; /* so one can see fadeBgColor properly */
}

.animatable {
  
  /* initially hide animatable objects */
  visibility: hidden;
  
  /* initially pause animatable objects their animations */
  -webkit-animation-play-state: paused;   
  -moz-animation-play-state: paused;     
  -ms-animation-play-state: paused;
  -o-animation-play-state: paused;   
  animation-play-state: paused; 
}

/* show objects being animated */
.animated {
  visibility: visible;
  
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;

  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -ms-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
}

/* CSS Animations (extracted from http://glifo.uiparade.com/) */
@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-20px);
	}	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes fadeInDown {
	0% {
		opacity: 0;
		-moz-transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-o-keyframes fadeInDown {
	0% {
		opacity: 0;
		-o-transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}



@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	60% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes bounceInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-2000px);
	}
	60% {
		-webkit-transform: translateX(20px);
	}

	80% {
		-webkit-transform: translateX(-5px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}

@-moz-keyframes bounceInLeft {
	0% {
		opacity: 0;
		-moz-transform: translateX(-2000px);
	}

	60% {
		-moz-transform: translateX(20px);
	}

	80% {
		-moz-transform: translateX(-5px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateX(0);
	}
}

@-o-keyframes bounceInLeft {
	0% {
		opacity: 0;
		-o-transform: translateX(-2000px);
	}

	60% {
		opacity: 1;
		-o-transform: translateX(20px);
	}

	80% {
		-o-transform: translateX(-5px);
	}

	100% {
		opacity: 1;
		-o-transform: translateX(0);
	}
}

@keyframes bounceInLeft {
	0% {
		opacity: 0;
		transform: translateX(-2000px);
	}

	60% {
		transform: translateX(20px);
	}

	80% {
		transform: translateX(-5px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@-webkit-keyframes bounceInRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(2000px);
	}

	60% {
		-webkit-transform: translateX(-20px);
	}

	80% {
		-webkit-transform: translateX(5px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}

@-moz-keyframes bounceInRight {
	0% {
		opacity: 0;
		-moz-transform: translateX(2000px);
	}

	60% {
		-moz-transform: translateX(-20px);
	}

	80% {
		-moz-transform: translateX(5px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateX(0);
	}
}

@-o-keyframes bounceInRight {
	0% {
		opacity: 0;
		-o-transform: translateX(2000px);
	}

	60% {
		-o-transform: translateX(-20px);
	}

	80% {
		-o-transform: translateX(5px);
	}

	100% {
		opacity: 1;
		-o-transform: translateX(0);
	}
}

@keyframes bounceInRight {
	0% {
		opacity: 0;
		transform: translateX(2000px);
	}

	60% {
		transform: translateX(-20px);
	}

	80% {
		transform: translateX(5px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(20px);
	}	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes fadeInUp {
	0% {
		opacity: 0;
		-moz-transform: translateY(20px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-o-keyframes fadeInUp {
	0% {
		opacity: 0;
		-o-transform: translateY(20px);
	}

	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@-webkit-keyframes bounceIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(.3);
	}
	50% {
		-webkit-transform: scale(1.05);
	}

	70% {
		-webkit-transform: scale(.9);
	}

	100% {
		opacity: 1;
		-webkit-transform: scale(1);
	}
}

@-moz-keyframes bounceIn {
	0% {
		opacity: 0;
		-moz-transform: scale(.3);
	}

	50% {
		-moz-transform: scale(1.05);
	}

	70% {
		-moz-transform: scale(.9);
	}

	100% {
		opacity: 1;
		-moz-transform: scale(1);
	}
}

@-o-keyframes bounceIn {
	0% {
		opacity: 0;
		-o-transform: scale(.3);
	}

	50% {
		-o-transform: scale(1.05);
	}

	70% {
		-o-transform: scale(.9);
	}

	100% {
		opacity: 1;
		-o-transform: scale(1);
	}
}

@keyframes bounceIn {
	0% {
		opacity: 0;
		transform: scale(.3);
	}

	50% {
		transform: scale(1.05);
	}

	70% {
		transform: scale(.9);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}
@-webkit-keyframes moveUp {
	0% {
		opacity: 1;
		-webkit-transform: translateY(40px);
	}	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes moveUp {
	0% {
		opacity: 1;
		-moz-transform: translateY(40px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-o-keyframes moveUp {
	0% {
		opacity: 1;
		-o-transform: translateY(40px);
	}

	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes moveUp {
	0% {
		opacity: 1;
		transform: translateY(40px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@-webkit-keyframes fadeBgColor {
	0%{
		background:none;
	}
  70%{
		background:none;
	}
	100%{
		background:#464646;
	}
}
@-o-keyframes fadeBgColor {
	0%{
		background:none;
	}
  70%{
		background:none;
	}
	100%{
		background:#464646;
	}
}
@keyframes fadeBgColor {
	0%{
		background:none;
	}
  70%{
		background:none;
	}
	100%{
		background:#464646;
	}
}

.animated.animationDelay{
	animation-delay:.4s;
	-webkit-animation-delay:.4s;
}
.animated.animationDelayMed{
	animation-delay:1.2s;
	-webkit-animation-delay:1.2s;
}
.animated.animationDelayLong{
	animation-delay:1.6s;
	-webkit-animation-delay:1.6s;
}
.animated.fadeBgColor {
	-webkit-animation-name: fadeBgColor;
	-moz-animation-name: fadeBgColor;
	-o-animation-name: fadeBgColor;
	animation-name: fadeBgColor;
}
.animated.bounceIn {
	-webkit-animation-name: bounceIn;
	-moz-animation-name: bounceIn;
	-o-animation-name: bounceIn;
	animation-name: bounceIn;
}
.animated.bounceInRight {
	-webkit-animation-name: bounceInRight;
	-moz-animation-name: bounceInRight;
	-o-animation-name: bounceInRight;
	animation-name: bounceInRight;
}
.animated.bounceInLeft {
	-webkit-animation-name: bounceInLeft;
	-moz-animation-name: bounceInLeft;
	-o-animation-name: bounceInLeft;
	animation-name: bounceInLeft;
}
.animated.fadeIn {
	-webkit-animation-name: fadeIn;
	-moz-animation-name: fadeIn;
	-o-animation-name: fadeIn;
	animation-name: fadeIn;
}
.animated.fadeInDown {
	-webkit-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-o-animation-name: fadeInDown;
	animation-name: fadeInDown;
}
.animated.fadeInUp {
	-webkit-animation-name: fadeInUp;
	-moz-animation-name: fadeInUp;
	-o-animation-name: fadeInUp;
	animation-name: fadeInUp;
}
.animated.moveUp {
	-webkit-animation-name: moveUp;
	-moz-animation-name: moveUp;
	-o-animation-name: moveUp;
	animation-name: moveUp;
}